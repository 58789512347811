import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/10.png"
import Placeholder from "../images/placeholder_penguin.svg"

const LECTURER = [
  {
    src: Placeholder,
    alt: "",
    personName: "Tomáš Baxa",
    text: [""],
  },
]

const ServerSideMereni: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper
        title="Server-side měření a jeho specifika (pro potřeby GCP ekosystém)"
        ogImage={OgImage}
      />

      <SyllabusHeader
        orangeText="Server-side měření a jeho specifika "
        blackText="(pro potřeby GCP ekosystém)"
        numberOfLesson="10/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>
          představení principu SGTM
          <div>rozdílnost oproti normálnímu GTM</div>
          <div>k čemu slouží a co principiálně umí</div>
          <div>možné výhody měření přes SGTM</div>
        </div>

        <div>
          nasazení SGTM
          <div>
            představení Google Cloud Platform
            <div>funkcionalita, struktura, schopnosti</div>
          </div>
          <div>
            nasazení SGTM
            <div>
              přes App Engine
              <div>potřebná práva</div>
              <div>domény</div>
            </div>
            <div>přes Cloud Run</div>
          </div>
          <div>další možnosti nasazení</div>
        </div>

        <div>
          základní nastavení a funkce
          <div>
            client
            <div>GA4 jako univerzální tracker</div>
            <div>GTM web container</div>
            <div>measurement protocol</div>
          </div>
          <div>
            tag
            <div>GA</div>
            <div>
              G Ads
              <div>vysvětlení, jak funguje</div>
            </div>
            <div>HTTP request</div>
            <div>
              vybrané tagy z templates (FB cAPI, import to BQ, JSON, Cookie, CJ)
            </div>
          </div>
          <div>
            proměnné
            <div>možnosti a omezení</div>
            <div>vysvětlení některých proměnných</div>
            <div>
              vybrané proměnné z templates (extract URL, encode URI, base64,
              from JSON, regex table, redact PII, G Sheets reader?)
            </div>
          </div>
          <div>triggery</div>
        </div>

        <div>
          SGTM pro více domén (webů)
          <div>nastavení</div>
          <div>princip návrhu struktury</div>
        </div>

        <div>
          debugging
          <div>debug náhled</div>
          <div>alertový systém neboli hlídání běhu</div>
        </div>

        <div>FB Conversion API implementace</div>
        <div>implementace kompletní cookie lišty za pomocí SGTM</div>
        <div>
          pokročilejší funkce
          <div>custom client</div>
          <div>custom tag</div>
          <div>custom variable</div>
          <div>preflight</div>
          <div>firestore a obohacování dat</div>
        </div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default ServerSideMereni
